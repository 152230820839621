<template>
  <div>
    <World />
  </div>
</template>

<script>
import World from '@/components/world/World.vue'

export default {
  name: 'Window',
  components: {
    World
  }
}
</script>
