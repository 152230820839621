<template>
  <v-overlay
    absolute
    :value="isNpcMsgVisible"
  >
    <v-card>
      <v-card-actions>
        <v-btn small @click="$store.commit('closeNpcMsgDialoge')">X</v-btn>
      </v-card-actions>
      <v-card-text v-html="msg" />
      <v-card-actions v-if="isNpcTaskAvailable">
        <v-btn @click="acceptTask">Accept task</v-btn>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>

<script>

export default {
  name: 'Dialoge',
  computed: {
    msg () {
      return this.$store.state.npcMessage
    },
    isNpcMsgVisible () {
      return this.$store.state.npcMessageIsVisible
    },
    isNpcTaskAvailable () {
      return this.$store.state.npcTaskCanBeAcepted
    }
  },
  methods: {
    acceptTask () {
      this.$store.commit('acceptQuest')
      this.$store.commit('closeNpcMsgDialoge')
    }
  }
}
</script>
