<template>
  <div class="progress justify-center">
    <div>
      <v-progress-circular
        :rotate="360"
        :size="100"
        :width="15"
        :value="playerHealth / playerMaxHealth * 100"
        color="#de360d"
      >
        {{ playerHealth }}
      </v-progress-circular>
    </div>

    <div>
      <div>
        <v-progress-linear
          :value="playerXP"
          height="25"
          color="success"
        >
          <strong>{{ playerXP }}</strong>
        </v-progress-linear>
      </div>

      <div>
        <v-btn disabled>1</v-btn>
        <v-btn disabled>2</v-btn>
        <v-btn disabled>3</v-btn>
        <v-btn disabled>4</v-btn>
        <v-btn disabled>5</v-btn>
      </div>
    </div>

    <div>
      <v-progress-circular
        :rotate="360"
        :size="100"
        :width="15"
        :value="playerEnergy / playerMaxEnergy * 100"
        color="#ffe033"
      >
        {{ playerEnergy }}
      </v-progress-circular>
    </div>

    <v-card class="mx-4">
      <v-card-text>
        Active quest(s):
        <div
          v-for="(quest, index) in listOfActiveQuests"
          :key="index"
        >
          {{ quest }}
        </div>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>
export default {
  name: 'UserIntrface',
  computed: {
    playerHealth () {
      return this.$store.state.player.life
    },
    playerEnergy () {
      return this.$store.state.player.energy
    },
    playerMaxHealth () {
      return this.$store.state.player.maxLife
    },
    playerMaxEnergy () {
      return this.$store.state.player.maxEnergy
    },
    playerXP () {
      return this.$store.state.player.xp
    },
    nextRequiredLvlXp () {
      return this.$store.state.player.levelUpMatrix[0].requiredXp
    },
    listOfActiveQuests () {
      return this.$store.getters.listOfActiveQuests
    }
  }
}
</script>

<style scoped>
.progress {
  display: inline-flex;
  align-items: center;
}
</style>
