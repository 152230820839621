<template>
  <div class="actionButtons">
    <v-btn
      class="ma-2"
      small
      @click="overlay = !overlay"
    >
      <v-icon dark>
        mdi-keyboard
      </v-icon>
    </v-btn>

    <v-overlay :value="overlay">
      <v-card>
        <v-card-text>
          Use <b>W,A,S,D</b> to move.<br>
          Press <b>Q</b> to take item<br>
          <b>E</b> to interact<br>
          <b>T</b> to talk<br>
          <b>X</b> to attack opponent<br>
          <b>I</b> to open or close inventory
        </v-card-text>
      </v-card>
    </v-overlay>

    <v-btn
      class="ma-2"
      small
      @click="$store.commit('showOrHideInventory')"
    >
      <v-icon dark>
        mdi-purse-outline
      </v-icon>
    </v-btn>

    <v-btn
      class="ma-2"
      color="purple"
      small
      disabled
    >
      <v-icon dark>
        mdi-wrench
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'ActionButtons',

  data: () => ({
    overlay: false
  }),

  watch: {
    overlay (val) {
      val && setTimeout(() => {
        this.overlay = false
      }, 3000)
    }
  }
}
</script>

<style scoped>
.actionButtons {
  width: 800px;
  margin: 0 auto;
}
</style>
