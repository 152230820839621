import Wolf from '@/assets/wolf.png'

const wolf = {
  hp: 10,
  maxHp: 10,
  damage: 1,
  defence: 0,
  exp: 10,
  type: 'ent',
  sprite: Wolf
}

export default wolf
