<template>
  <div class="player" :style="playerCss">
    <!-- <span class="player_name">{{ playerName }}</span> -->
    <v-progress-linear
      v-if="gathering"
      style="left: 40px; top: -10px; position: absolute;"
      indeterminate
      color="success"
    ></v-progress-linear>
    <div class="item_worn_sword item_worn" v-if="sword"></div>
    <div class="item_worn_pickaxe item_worn" v-if="pick"></div>
    <div class="item_worn_hatchet item_worn" v-if="axe"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { SPRITE_SIZE } from '@/config/constants'

export default {
  name: 'Player',
  data () {
    return {
      playerCss: {
        '--height': SPRITE_SIZE + 'px',
        '--width': SPRITE_SIZE + 'px',
        '--left': 0 + 'px',
        '--top': 0 + 'px'
      }
    }
  },
  computed: {
    ...mapState({
      player: 'player'
    }),
    playerName () {
      return this.player.name
    },
    playerHealth () {
      return this.player.life > 0
    },
    gathering () {
      return this.player.gathering
    },
    sword () {
      return this.player.items.hasSword && this.player.worn.sword === 'sword'
    },
    pick () {
      return this.player.items.hasPickaxe && this.player.worn.sword === 'pickaxe'
    },
    axe () {
      return this.player.items.hasHatchet && this.player.worn.sword === 'hatchet'
    }
  },
  watch: {
    // requires native array method modifiers on player.position
    '$store.state.player.position': {
      deep: true,
      handler (newVal) {
        this.playerCss['--left'] = newVal[0] + 'px'
        this.playerCss['--top'] = newVal[1] + 'px'
      }
    }
  },
  created () {
    window.addEventListener('keypress', this.doCommand)
  },
  destroyed () {
    window.removeEventListener('keypress', this.doCommand)
  },
  methods: {
    doCommand (e) {
      if (this.playerHealth) {
        const key = String.fromCharCode(e.keyCode).toLowerCase()

        switch (key) {
          case 'w':
            this.$store.commit('move', 'NORTH')
            break
          case 'a':
            this.$store.commit('move', 'WEST')
            break
          case 's':
            this.$store.commit('move', 'SOUTH')
            break
          case 'd':
            this.$store.commit('move', 'EAST')
            break
          case 'q':
            this.$store.commit('takeItem')
            break
          case 'i':
            this.$store.commit('showOrHideInventory')
            break
          case 'e':
            this.$store.commit('interactWithObject', 'gather')
            break
          case 'x':
            this.$store.commit('interactWithObject', 'attack')
            break
          case 't':
            this.$store.commit('interactWithObject', 'talk')
            break
          default:
            // do nothing
            break
        }

        // custom solution since computed prop did not work
        this.playerCss['--left'] = this.player.position[0] + 'px'
        this.playerCss['--top'] = this.player.position[1] + 'px'
      }
    }
  }
}
</script>

<style scoped>
.player {
  position: absolute;
  background-image: url('~@/assets/player.png');
  background-size: cover;
  height: var(--height);
  width: var(--width);
  top: var(--top);
  left: var(--left);
}
.player_name {
  position: absolute;
  background-color: rosybrown;
  opacity: 0.7;
  border-radius: 7px;
  top: -26px;
  left: 0px;
}
.player_life {
  position: absolute;
  background-color: rosybrown;
  opacity: 0.7;
  border-radius: 7px;
  top: -41px;
  left: -2px;
  font-size: 10px;
  width: 43px;
}

.item_worn {
  display: inline-flex;
  background-size: 23px 23px;
  background-repeat: no-repeat;
  height: 23px;
  width: 23px;
  margin-left: 45px;
  margin-top: 25px;
}

.item_worn_pickaxe {
  background-image: url('~@/assets/pickaxe.png');
}

.item_worn_hatchet {
  background-image: url('~@/assets/hatchet.png');
}

.item_worn_sword {
  background-image: url('~@/assets/sword.png');
}
</style>
