<template>
  <v-overlay :value="isMarketVisible">
    <v-card width="400px">
      <v-card-actions class="market-action-buttons">
        <v-btn
          @click="showOrHideMarket"
          icon
          small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-actions>
      <v-card-title class="justify-center pt-0">Trade</v-card-title>
      <v-card-text>
        <div
          v-for="(item, index) in marketItems"
          :key="index"
          class="item-holder ma-1">
            <div
              :class="`item_${item.name} item ma-1`"
            >
            </div>
            {{ item.price }} gold<br>
            <v-btn @click="buyItem(item)">Buy</v-btn>
            <span class="px-1">You have {{ $store.state.player.items[item.ref] ? 'it' : '0' }}</span>
        </div>
      </v-card-text>
      <v-card-text>
          <div
          v-for="(item, index) in playerItems"
          :key="index"
          class="item-holder ma-1">
            <div
              :class="`item_${item.name} item ma-1`"
            >
            </div>
            {{ item.price }} gold<br>
            <v-btn @click="sellItem(item)">Sell</v-btn>
            <span class="px-1">You have {{ $store.state.player.items[item.name] }}</span>
        </div>
      </v-card-text>
      <v-card-text>
        Coins in your packet: {{ $store.state.player.coins }}
      </v-card-text>
    </v-card>
  </v-overlay>
</template>

<script>

export default {
  name: 'Market',
  data () {
    return {
      marketItems: [
        {
          name: 'sword',
          ref: 'hasSword',
          price: 1
        },
        {
          name: 'pickaxe',
          ref: 'hasPickaxe',
          price: 1
        },
        {
          name: 'hatchet',
          ref: 'hasHatchet',
          price: 1
        }
      ],
      playerItems: [
        {
          name: 'logs',
          price: 0.25
        },
        {
          name: 'rocks',
          price: 0.35
        }
      ]
    }
  },
  computed: {
    isMarketVisible () {
      return this.$store.state.isMarketVisible
    }
  },
  methods: {
    showOrHideMarket () {
      this.$store.commit('showOrHideMarket')
    },
    buyItem (item) {
      this.$store.commit('buyItem', item)
    },
    sellItem (item) {
      this.$store.commit('sellItem', item)
    }
  }
}
</script>

<style scoped>

.item-holder {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid grey;
  border-radius: 5px;
}

.item {
  /* display: inline-flex; */
  background-size: 60px 60px;
  background-repeat: no-repeat;
  height: 60px;
  width: 60px;
  /* margin-left: 2prgb(226, 19, 19)x; */
  border: 1px solid grey;
  border-radius: 5px;
}

.item_sword {
  background-image: url('~@/assets/sword.png');
}

.item_pickaxe {
  background-image: url('~@/assets/pickaxe.png');
}

.item_hatchet {
  background-image: url('~@/assets/hatchet.png');
}

.market-action-buttons {
  justify-content: flex-end;
}
</style>
