export const tiles1 = [
  [0, 0, 10, 0, 0, 0, 0, 0, 10, 0, 0, 0, 0, 0, 10, 11, 0, 0, 10, 0],
  [0, 0, 0, 0, 10, 0, 0, 0, 10, 10, 0, 0, 0, 0, 10, 0, 0, 10, 0, 0],
  [10, 0, 0, 0, 0, 10, 0, 0, 0, 10, 0, 10, 0, 0, 0, 0, 10, 11, 0, 0],
  [0, 0, 0, 0, 0, 0, 10, 0, 0, 49, 0, 0, 0, 10, 0, 10, 0, 0, 0, 0],
  [0, 10, 10, 0, 0, 0, 0, 0, 0, 0, 0, 48, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 10, 0, 0, 100, 0, 50, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [10, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 10, 0, 0, 0, 0, 48, 0, 0, 0, 0, 0, 10, 0, 0, 0, 0, 0, 0],
  [11, 10, 0, 11, 0, 0, 10, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [10, 0, 10, 0, 10, 0, 0, 0, 0, 0, 0, 10, 0, 0, 0, 0, 0, 0, 0, 0]
]

export const monsters1 = [
  /* {
    type: 'wolf',
    position: [8, 18]
  } */
]
