<template>
  <v-overlay
    absolute
    :value="playerHealth"
  >
    <h1 class="red--text">D E A D</h1>
    <h3>At this time you haven't lost anything other than your head.</h3>
    <v-btn
      color="success"
      @click="$store.commit('respawn')"
      small
    >
      Respawn
    </v-btn>
  </v-overlay>
</template>

<script>

export default {
  name: 'Died',
  computed: {
    playerHealth () {
      return this.$store.state.player.life <= 0
    }
  }
}
</script>
