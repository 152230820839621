<template>
  <v-app id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <Action-Buttons />
    <router-view />
    <User-Interface />
  </v-app>
</template>

<script>
import ActionButtons from '@/components/gameWindow/ActionButtons.vue'
import UserInterface from '@/components/gameWindow/UserInterface.vue'

export default {
  name: 'App',

  components: {
    ActionButtons,
    UserInterface
  },

  data: () => ({
    //
  })
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
