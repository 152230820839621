import Vue from 'vue'
import Vuex from 'vuex'

import { SPRITE_SIZE, MAP_HEIGHT, MAP_WIDTH, BARRIER_ID } from '../config/constants'
import { tiles1, monsters1 } from '@/components/map/maps/1/'
import { tiles2, monsters2 } from '@/components/map/maps/2/'
import { tiles3, monsters3 } from '@/components/map/maps/3/'
import { tiles4, monsters4 } from '@/components/map/maps/4/'
import { tiles5, monsters5 } from '@/components/map/maps/5/'
import { tiles6, monsters6 } from '@/components/map/maps/6/'
import { tiles7, monsters7 } from '@/components/map/maps/7/'
import { tiles8, monsters8 } from '@/components/map/maps/8/'
import { tiles9, monsters9 } from '@/components/map/maps/9/'
// import { tiles, monsters } from '@/components/map/maps/empty/'
// import { AvailableHops, DIRECTION, GameState, Monster } from './interface'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    roundCounter: 0, // helper variable for rerendering (+counting the rounds)
    showInventory: false,
    isMarketVisible: false,
    player: {
      name: 'Player',
      gathering: false,
      lvl: 0,
      xp: 10,
      life: 20,
      maxLife: 20,
      energy: 10,
      maxEnergy: 10,
      coins: 0,
      hitCount: 2,
      lastMovement: '',
      position: [0, 0],
      currentMapId: 1,
      items: {
        hasSword: false,
        hasHatchet: false,
        hasPickaxe: false,
        logs: 0,
        rocks: 0,
        healingPotion: 0
      },
      worn: {
        helm: '',
        armour: '',
        belt: '',
        legs: '',
        sword: '',
        shield: '',
        boots: ''
      },
      levelUpMatrix: [
        { level: 1, requiredXp: 20 },
        { level: 2, requiredXp: 50 },
        { level: 3, requiredXp: 100 },
        { level: 4, requiredXp: 180 },
        { level: 5, requiredXp: 270 }
      ]
    },
    tiles: tiles1,
    monsters: monsters1,
    actualOpponentList: [],
    npcMessage: '',
    npcMessageIsVisible: false,
    npcTaskCanBeAcepted: false,
    npcTaskToAssign: '',
    quest: [
      {
        name: 'fireFeed',
        hasStarted: false,
        logsInFire: false,
        completed: false
      }
    ]
  },
  getters: {
    swordWorn (state) {
      return state.player.items.hasSword && state.player.worn.sword === 'sword'
    },
    pickWorn (state) {
      return state.player.items.hasPickaxe && state.player.worn.sword === 'pickaxe'
    },
    axeWorn (state) {
      return state.player.items.hasHatchet && state.player.worn.sword === 'hatchet'
    },
    listOfActiveQuests (state) {
      return state.quest.map(quest => {
        if (quest.hasStarted && !quest.completed) {
          return quest.name
        }
      })
    }
  },
  mutations: {
    changeMap (state, direction) {
      const currentMapId = state.player.currentMapId

      /* MAP relations
        4, 5, 6
        1, 2, 3
        7, 8, 9
      */

      const availableHops = [
        { mapId: 0, NORTH: 0, WEST: 0, SOUTH: 0, EAST: 0 }, // 0 is a dummy, 0-s doesn't lead anywhere
        { mapId: 1, NORTH: 4, WEST: 0, SOUTH: 7, EAST: 2 }, // user starts in this MAP
        { mapId: 2, NORTH: 5, WEST: 1, SOUTH: 8, EAST: 3 },
        { mapId: 3, NORTH: 6, WEST: 2, SOUTH: 9, EAST: 0 },
        { mapId: 4, NORTH: 0, WEST: 0, SOUTH: 1, EAST: 5 },
        { mapId: 5, NORTH: 0, WEST: 4, SOUTH: 2, EAST: 6 },
        { mapId: 6, NORTH: 0, WEST: 5, SOUTH: 3, EAST: 0 },
        { mapId: 7, NORTH: 1, WEST: 0, SOUTH: 0, EAST: 8 },
        { mapId: 8, NORTH: 2, WEST: 7, SOUTH: 0, EAST: 9 },
        { mapId: 9, NORTH: 3, WEST: 8, SOUTH: 0, EAST: 0 }
      ]

      // nextMapId should be calculated from the direction
      const nextMapId = availableHops[currentMapId][direction]

      const calculatePositionOnMapChange = (direction) => {
        switch (direction) {
          case 'NORTH':
            state.player.position = [SPRITE_SIZE * 9, SPRITE_SIZE * 9]
            break
          case 'WEST':
            state.player.position = [SPRITE_SIZE * 19, SPRITE_SIZE * 5]
            break
          case 'SOUTH':
            state.player.position = [SPRITE_SIZE * 9, 0]
            break
          case 'EAST':
            state.player.position = [0, SPRITE_SIZE * 5]
            break
        }
      }

      const toDoOnMapChange = (tileId, monsterId) => {
        state.actualOpponentList = []
        state.tiles = tileId
        state.monsters = monsterId
        calculatePositionOnMapChange(direction)
      }

      if (nextMapId > 0) {
        state.player.currentMapId = nextMapId

        switch (nextMapId) {
          case 1:
            toDoOnMapChange(tiles1, monsters1)
            break
          case 2:
            toDoOnMapChange(tiles2, monsters2)
            break
          case 3:
            toDoOnMapChange(tiles3, monsters3)
            break
          case 4:
            toDoOnMapChange(tiles4, monsters4)
            break
          case 5:
            toDoOnMapChange(tiles5, monsters5)
            break
          case 6:
            toDoOnMapChange(tiles6, monsters6)
            break
          case 7:
            toDoOnMapChange(tiles7, monsters7)
            break
          case 8:
            toDoOnMapChange(tiles8, monsters8)
            break
          case 9:
            toDoOnMapChange(tiles9, monsters9)
            break
        }
      }
    },
    respawn (state) {
      // player.vue watcher require more advanced position rewrite
      state.player.position.splice(0, 2)
      state.player.position = [0, 0]
      state.player.life = state.player.maxLife
      state.player.energy = state.player.maxEnergy
      state.actualOpponentList = []
      state.player.currentMapId = 1
      state.tiles = tiles1
      state.monsters = monsters1
      // state.player.position = [7 * SPRITE_SIZE, 5 * SPRITE_SIZE]
      state.roundCounter++
    },
    monsterRegistration (state, monsterData) {
      state.actualOpponentList.push(monsterData)
    },
    monsterMove (state) {
      state.actualOpponentList.forEach((monster, index) => {
        if (monster.stilCount) {
          const playerY = state.player.position[0] / SPRITE_SIZE
          const playerX = state.player.position[1] / SPRITE_SIZE
          const monsterX = monster.position[0]
          const monsterY = monster.position[1]

          /* const playerYinPix = state.player.position[0]
          const playerXinPix = state.player.position[1]
          const monsterXinPix = monster.position[0] * SPRITE_SIZE
          const monsterYinPix = monster.position[1] * SPRITE_SIZE */

          // X vízszintes tengely 0 - 800 LEFT
          // Y függőleges tengely 0 - 400 TOP

          // + TASKS:
          // + task calculate monster view distance (3X40 = 120px)
          // + task ne lépjenek egymásra a lények (done)
          // + task ne lépjenek a playerre a lények (halfly done) -> attack

          // monster chatches player + doesn't move on barriers
          if (playerY < monsterY && state.tiles[monsterX][monsterY - 1] < BARRIER_ID) {
            if (playerY === monsterY - 1 && playerX === monsterX) {
              // console.log('attack!')
              state.player.life = state.player.life - state.actualOpponentList[index].damage
            } else {
              // console.log('left')
              let allowedStep = true
              state.actualOpponentList.forEach(el => {
                if (el.position[1] === monsterY - 1 && el.position[0] === monsterX) {
                  allowedStep = false
                }
              })

              if (allowedStep) {
                state.actualOpponentList[index].position[1]--
              }
            }
          } else if (playerX < monsterX && state.tiles[monsterX - 1][monsterY] < BARRIER_ID) {
            if (playerY === monsterY && playerX === monsterX - 1) {
              // console.log('attack!')
              state.player.life = state.player.life - state.actualOpponentList[index].damage
            } else {
              // console.log('top')
              let allowedStep = true
              state.actualOpponentList.forEach(el => {
                if (el.position[0] === monsterX - 1 && el.position[1] === monsterY) {
                  allowedStep = false
                }
              })

              if (allowedStep) {
                state.actualOpponentList[index].position[0]--
              }
            }
          } else if (playerY > monsterY && state.tiles[monsterX][monsterY + 1] < BARRIER_ID) {
            if (playerY === monsterY + 1 && playerX === monsterX) {
              // console.log('attack!')
              state.player.life = state.player.life - state.actualOpponentList[index].damage
            } else {
              // console.log('right')
              let allowedStep = true
              state.actualOpponentList.forEach(el => {
                if (el.position[1] === monsterY + 1 && el.position[0] === monsterX) {
                  allowedStep = false
                }
              })

              if (allowedStep) {
                state.actualOpponentList[index].position[1]++
              }
            }
          } else if (playerX > monsterX && state.tiles[monsterX + 1][monsterY] < BARRIER_ID) {
            if (playerY === monsterY && playerX === monsterX + 1) {
              // console.log('attack!')
              state.player.life = state.player.life - state.actualOpponentList[index].damage
            } else {
              // console.log('bottom')
              let allowedStep = true
              state.actualOpponentList.forEach(el => {
                if (el.position[0] === monsterX + 1 && el.position[1] === monsterY) {
                  allowedStep = false
                }
              })

              if (allowedStep) {
                state.actualOpponentList[index].position[0]++
              }
            }
          }
        }
      })
    },
    attackOpponent (state, focusLocation) {
      state.actualOpponentList.forEach((monster, index) => {
        if (focusLocation[0] === monster.position[1] && focusLocation[1] === monster.position[0]) {
          state.roundCounter++
          // chances and many other things should be calculated during the fight
          // who can hit first, hit change, damage amount and so on..
          // monster attack is handled in monsterMove
          state.actualOpponentList[index].hp = state.actualOpponentList[index].hp - state.player.hitCount
        }

        // opponent dies
        if (monster.hp <= 0 && monster.stilCount) {
          state.player.xp = state.player.xp + state.actualOpponentList[index].exp
          state.actualOpponentList[index].position = [6666, 6666]
          state.actualOpponentList[index].stilCount = false
        }
      })
    },
    move (state, direction) {
      // when gathering no move is possible
      if (!state.player.gathering) {
        state.roundCounter++
        // calculate new position
        let newPosition = [0, 0]
        switch (direction) {
          case 'NORTH':
            newPosition = [state.player.position[0], state.player.position[1] - SPRITE_SIZE]
            break
          case 'WEST':
            newPosition = [state.player.position[0] - SPRITE_SIZE, state.player.position[1]]
            break
          case 'SOUTH':
            newPosition = [state.player.position[0], state.player.position[1] + SPRITE_SIZE]
            break
          case 'EAST':
            newPosition = [state.player.position[0] + SPRITE_SIZE, state.player.position[1]]
            break
        }

        // X coordinates should be exactly defined
        let x = 0
        if (typeof (newPosition[1] / SPRITE_SIZE) === 'number' && newPosition[1] / SPRITE_SIZE >= 0 && newPosition[1] / SPRITE_SIZE <= 9) {
          x = newPosition[1] / SPRITE_SIZE
        }
        // const x = newPosition[1] / SPRITE_SIZE
        const y = newPosition[0] / SPRITE_SIZE
        let nextTile = state.tiles[x][y]

        // avoid stepping on a monster!
        state.actualOpponentList.forEach(opponent => {
          if (x === opponent.position[0] && y === opponent.position[1]) {
            // nextTile set to dummy value (greater than 10)
            nextTile = 60000
          }
        })

        // check if movement is possible (nextTile should be below id: 10)
        switch (direction) {
          case 'NORTH':
            state.player.lastMovement = direction
            if (state.player.position[1] - SPRITE_SIZE === SPRITE_SIZE * -1) {
              this.commit('changeMap', direction)
            } else if (state.player.position[1] - SPRITE_SIZE >= 0 && state.player.position[1] <= MAP_HEIGHT - SPRITE_SIZE && nextTile < BARRIER_ID) {
              state.player.position[1] = state.player.position[1] - SPRITE_SIZE
            }
            break
          case 'WEST':
            state.player.lastMovement = direction
            if (state.player.position[0] - SPRITE_SIZE === SPRITE_SIZE * -1) {
              this.commit('changeMap', direction)
            } else if (state.player.position[0] - SPRITE_SIZE >= 0 && state.player.position[0] <= MAP_WIDTH - SPRITE_SIZE && nextTile < BARRIER_ID) {
              state.player.position[0] = state.player.position[0] - SPRITE_SIZE
            }
            break
          case 'SOUTH':
            state.player.lastMovement = direction
            // OK
            if (state.player.position[1] === MAP_HEIGHT - SPRITE_SIZE) {
              this.commit('changeMap', direction)
            } else if (state.player.position[1] >= 0 && state.player.position[1] <= MAP_HEIGHT - SPRITE_SIZE * 2 && nextTile < BARRIER_ID) {
              state.player.position[1] = state.player.position[1] + SPRITE_SIZE
            }
            break
          case 'EAST':
            state.player.lastMovement = direction
            if (state.player.position[0] === MAP_WIDTH - SPRITE_SIZE) {
              this.commit('changeMap', direction)
            } else if (state.player.position[0] >= 0 && state.player.position[0] <= MAP_WIDTH - SPRITE_SIZE * 2 && nextTile < BARRIER_ID) {
              state.player.position[0] = state.player.position[0] + SPRITE_SIZE
            }
            break
          default:
            // do nothing
            break
        }

        // if theres an opponent on the map
        if (state.actualOpponentList.length) {
          this.commit('monsterMove')
        }
      }
    },
    interactWithObject (state, action) {
      if (state.player.lastMovement !== '') {
        switch (state.player.lastMovement) {
          case 'NORTH':
            if (action === 'gather') {
              this.commit('interact', [state.tiles[state.player.position[1] / SPRITE_SIZE - 1][state.player.position[0] / SPRITE_SIZE], [state.player.position[0] / SPRITE_SIZE, state.player.position[1] / SPRITE_SIZE - 1]])
            }
            if (action === 'attack') {
              this.commit('attackOpponent', [state.player.position[0] / SPRITE_SIZE, state.player.position[1] / SPRITE_SIZE - 1])
            }
            if (action === 'talk') {
              this.commit('talk', [state.tiles[state.player.position[1] / SPRITE_SIZE - 1][state.player.position[0] / SPRITE_SIZE], [state.player.position[0] / SPRITE_SIZE, state.player.position[1] / SPRITE_SIZE - 1]])
            }
            break
          case 'WEST':
            if (action === 'gather') {
              this.commit('interact', [state.tiles[state.player.position[1] / SPRITE_SIZE][state.player.position[0] / SPRITE_SIZE - 1], [state.player.position[0] / SPRITE_SIZE - 1, state.player.position[1] / SPRITE_SIZE]])
            }
            if (action === 'attack') {
              this.commit('attackOpponent', [state.player.position[0] / SPRITE_SIZE - 1, state.player.position[1] / SPRITE_SIZE])
            }
            if (action === 'talk') {
              this.commit('talk', [state.tiles[state.player.position[1] / SPRITE_SIZE][state.player.position[0] / SPRITE_SIZE - 1], [state.player.position[0] / SPRITE_SIZE - 1, state.player.position[1] / SPRITE_SIZE]])
            }
            break
          case 'SOUTH':
            if (action === 'gather') {
              this.commit('interact', [state.tiles[state.player.position[1] / SPRITE_SIZE + 1][state.player.position[0] / SPRITE_SIZE], [state.player.position[0] / SPRITE_SIZE, state.player.position[1] / SPRITE_SIZE + 1]])
            }
            if (action === 'attack') {
              this.commit('attackOpponent', [state.player.position[0] / SPRITE_SIZE, state.player.position[1] / SPRITE_SIZE + 1])
            }
            if (action === 'talk') {
              this.commit('talk', [state.tiles[state.player.position[1] / SPRITE_SIZE + 1][state.player.position[0] / SPRITE_SIZE], [state.player.position[0] / SPRITE_SIZE, state.player.position[1] / SPRITE_SIZE + 1]])
            }
            break
          case 'EAST':
            if (action === 'gather') {
              this.commit('interact', [state.tiles[state.player.position[1] / SPRITE_SIZE][state.player.position[0] / SPRITE_SIZE + 1], [state.player.position[0] / SPRITE_SIZE + 1, state.player.position[1] / SPRITE_SIZE]])
            }
            if (action === 'attack') {
              this.commit('attackOpponent', [state.player.position[0] / SPRITE_SIZE + 1, state.player.position[1] / SPRITE_SIZE])
            }
            if (action === 'talk') {
              this.commit('talk', [state.tiles[state.player.position[1] / SPRITE_SIZE][state.player.position[0] / SPRITE_SIZE + 1], [state.player.position[0] / SPRITE_SIZE + 1, state.player.position[1] / SPRITE_SIZE]])
            }
            break
        }
        // if theres an opponent on the map
        if (state.actualOpponentList.length) {
          this.commit('monsterMove')
        }
      }
    },
    talk (state, focusLocation) {
      if (focusLocation[0] >= BARRIER_ID) {
        // horse
        if (focusLocation[0] === 49) {
          state.npcMessageIsVisible = true
          state.npcTaskCanBeAcepted = false
          state.npcMessage = '<b>You</b>: Are you a horse or a donkey?'
        }
        // instructor at basecamp
        if (focusLocation[0] === 50) {
          state.npcMessageIsVisible = true
          // QUEST START
          if (!state.quest[0].hasStarted) {
            state.npcTaskCanBeAcepted = true
            state.npcTaskToAssign = 'fireFeed'
            state.npcMessage = '<b>Strange old man</b>: "Dear Traveler! I am in a big trouble!<br> I am old as hell. I\'ve lost my axe and run out of logs.<br> Can you help me to find my lost item and cut some trees to feed this campfire?<br><br>Additional info from the old man: The world around us is locked by a magical force.<br> You can only reach & discover certain areas on the map.<br> As far as I remember I came from East."'
          }
          // Already started
          if (state.quest[0].hasStarted && !state.player.items.hasHatchet) {
            state.npcTaskCanBeAcepted = false
            state.npcMessage = '<b>Strange old man</b>: "Have you find my axe?<br> Please keep looking."'
          }
          // In Middle of the quest
          if (state.quest[0].hasStarted && state.player.items.hasHatchet) {
            state.npcTaskCanBeAcepted = false
            state.npcMessage = '<b>Strange old man</b>: "I see. You found it!<br> Put some logs on fire and get back to me."'
          }
          // Finished
          if (state.quest[0].hasStarted && state.quest[0].logsInFire) {
            state.npcTaskCanBeAcepted = false
            // there should be an achivement box pop up or something to celebrate
            state.npcMessage = '<b>Strange old man</b>: "Thank you my dear to feed our fire!<br> Hence I am not a rich man you can keep my Axe with you as a gift.<br> I hope it will serve you well."'
          }
        }
      }
    },
    drainPlayerEnergyBy (state, amount) {
      state.player.energy = state.player.energy - amount
    },
    handlePlayerAction (state, activity) {
      if (state.player.energy - activity.drainEnergy >= 0) {
        state.player.gathering = true
        setTimeout(() => {
          activity.activity === 'logs' ? this.commit('removeTreeFromMap', activity.location) : this.commit('removeItemFromMap', activity.location)
          state.player.items[activity.activity]++
          this.commit('drainPlayerEnergyBy', activity.drainEnergy)
          state.player.gathering = false
        }, Math.floor(Math.random() * 12000) + 8000) // random milisec 10 000 to 12 0000
      }
    },
    interact (state, focusLocation) {
      // ha van ott valami és interactálhat vele (van szerszáma) akkor action
      // itemmel van dolgunk id 10 felett
      if (focusLocation[0] >= BARRIER_ID) {
        // 10-19-ig fával van dolgunk + tool
        if (focusLocation[0] >= 10 && focusLocation[0] <= 19 && state.player.items.hasHatchet) {
          this.commit('handlePlayerAction', { drainEnergy: 3, activity: 'logs', location: focusLocation[1] })
          // 20-29-ig kővel van dolgunk + tool
        } else if (focusLocation[0] >= 20 && focusLocation[0] <= 29 && state.player.items.hasPickaxe) {
          this.commit('handlePlayerAction', { drainEnergy: 4, activity: 'rocks', location: focusLocation[1] })
          // put logs on fire
        } else if (focusLocation[0] === 100) {
          if (state.player.items.logs > 0) {
            // 1 below required for quest
            state.quest[0].logsInFire = true
            state.player.items.logs--
          }
        }
        // if theres an opponent on the map
        if (state.actualOpponentList.length) {
          this.commit('monsterMove')
        }
      }
    },
    takeItem (state) {
      // id 8 = pickaxe, 9 = hatchet
      const resolveItemInTile = state.tiles[state.player.position[1] / SPRITE_SIZE][state.player.position[0] / SPRITE_SIZE]
      switch (resolveItemInTile) {
        case 6:
          state.player.items.healingPotion++
          this.commit('removeItemFromMap', [state.player.position[0] / SPRITE_SIZE, state.player.position[1] / SPRITE_SIZE])
          break
        case 7:
          state.player.items.hasSword = true
          if (state.player.worn.sword.length === 0) {
            this.commit('wearItem', { type: 'sword', name: 'sword' })
          }
          this.commit('removeItemFromMap', [state.player.position[0] / SPRITE_SIZE, state.player.position[1] / SPRITE_SIZE])
          break
        case 8:
          state.player.items.hasPickaxe = true
          if (state.player.worn.sword.length === 0) {
            this.commit('wearItem', { type: 'sword', name: 'pickaxe' })
          }
          this.commit('removeItemFromMap', [state.player.position[0] / SPRITE_SIZE, state.player.position[1] / SPRITE_SIZE])
          break
        case 9:
          state.player.items.hasHatchet = true
          if (state.player.worn.sword.length === 0) {
            this.commit('wearItem', { type: 'sword', name: 'hatchet' })
          }
          this.commit('removeItemFromMap', [state.player.position[0] / SPRITE_SIZE, state.player.position[1] / SPRITE_SIZE])
          break
      }
      // if theres an opponent on the map
      if (state.actualOpponentList.length) {
        this.commit('monsterMove')
      }
    },
    removeItemFromMap (state, position) {
      // workaround with temp var. (otherwise it doesnt disappear)
      const temp = state.tiles
      state.tiles = []
      temp[position[1]][position[0]] = 0
      state.tiles = temp
    },
    removeTreeFromMap (state, position) {
      const temp = state.tiles
      state.tiles = []
      temp[position[1]][position[0]] = 19
      state.tiles = temp
    },
    showOrHideInventory (state) {
      state.showInventory = !state.showInventory
    },
    closeNpcMsgDialoge (state) {
      state.npcMessageIsVisible = false
    },
    acceptQuest (state) {
      state.quest.find(el => el.name === state.npcTaskToAssign).hasStarted = true
    },
    showOrHideMarket (state) {
      state.isMarketVisible = !state.isMarketVisible
    },
    buyItem (state, item) {
      if (state.player.coins - item.price >= 0) {
        switch (item.name) {
          case 'sword': {
            if (!state.player.items.hasSword) {
              state.player.coins -= item.price
              state.player.items.hasSword = true
            } else {
              console.log('already have one')
            }
            break
          }
          case 'pickaxe': {
            if (!state.player.items.hasPickaxe) {
              state.player.coins -= item.price
              state.player.items.hasPickaxe = true
            } else {
              console.log('already have one')
            }
            break
          }
          case 'hatchet': {
            if (!state.player.items.hasHatchet) {
              state.player.coins -= item.price
              state.player.items.hasHatchet = true
            } else {
              console.log('already have one')
            }
            break
          }
        }
      } else {
        console.log('cannot buy it')
      }
    },
    sellItem (state, item) {
      if (state.player.items[item.name] - 1 >= 0) {
        state.player.items[item.name] -= 1
        state.player.coins += item.price
      } else {
        console.log('have no item to sell')
      }
    },
    wearItem (state, item) {
      state.player.worn[item.type] = item.name
    },
    takeOffItem (state, itemType) {
      state.player.worn[itemType] = ''
    }
  },
  actions: {
  },
  modules: {
  }
})
