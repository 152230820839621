<template>
  <v-overlay :value="invStatus">
    <v-card width="300px">
      <v-card-actions>
        <v-btn @click="closeInventory" small>X</v-btn>
      </v-card-actions>
      <v-card-text>
        <b>Inventory</b><br><br>
        Worn item<br>
        <div class="item_sword item" v-if="swordWorn" @click="$store.commit('takeOffItem', 'sword')"></div>
        <div class="item_pickaxe item" v-if="pickWorn" @click="$store.commit('takeOffItem', 'sword')"></div>
        <div class="item_hatchet item" v-if="axeWorn" @click="$store.commit('takeOffItem', 'sword')"></div>
        <div class="item" v-if="!swordWorn && !pickWorn && !axeWorn">-</div>
        <br><br>
        Owned items<br>
        <div class="item_sword item" v-if="sword" @click="$store.commit('wearItem', { type: 'sword', name: 'sword' })"></div>
        <div class="item_pickaxe item" v-if="pick" @click="$store.commit('wearItem', { type: 'sword', name: 'pickaxe' })"></div>
        <div class="item_hatchet item" v-if="axe" @click="$store.commit('wearItem', { type: 'sword', name: 'hatchet' })"></div>
        <div class="item" v-if="!sword && !pick && !axe">-</div>
        <br><br>
        Materials: {{ logs }} logs & {{ rocks }} rocks
      </v-card-text>
    </v-card>
  </v-overlay>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Inventory',
  computed: {
    ...mapGetters(['swordWorn', 'pickWorn', 'axeWorn']),
    invStatus () {
      return this.$store.state.showInventory
    },
    sword () {
      return this.$store.state.player.items.hasSword
    },
    pick () {
      return this.$store.state.player.items.hasPickaxe
    },
    axe () {
      return this.$store.state.player.items.hasHatchet
    },
    logs () {
      return this.$store.state.player.items.logs
    },
    rocks () {
      return this.$store.state.player.items.rocks
    }
  },
  methods: {
    closeInventory () {
      this.$store.commit('showOrHideInventory')
    }
  }
}
</script>

<style scoped>
.item {
  display: inline-flex;
  background-size: 23px 23px;
  background-repeat: no-repeat;
  height: 23px;
  width: 23px;
  margin-left: 2prgb(226, 19, 19)x;
}

.item_sword {
  background-image: url('~@/assets/sword.png');
}

.item_pickaxe {
  background-image: url('~@/assets/pickaxe.png');
}

.item_hatchet {
  background-image: url('~@/assets/hatchet.png');
}
</style>
