<template>
  <div class="map" :style="mapCss">
    <div class="row" v-for="(row, index) in tiles" :key="index">
      <div
        class="tile"
        v-bind:class="{
          'dust': tile === 1,
          'portal': tile === 5,
          'healing_potion': tile === 6,
          'sword': tile === 7,
          'pickaxe': tile === 8,
          'hatchet': tile === 9,
          'tree': tile === 10,
          'tree_two': tile === 11,
          'trunk': tile === 19,
          'rock': tile === 20,
          'rock_two': tile === 21,
          'campfire': tile === 100,
          'cave': tile === 101,
          'tent': tile === 48,
          'horse': tile === 49,
          'instructor-1': tile === 50
        }"
        v-for="(tile, index) in row"
        :key="index"
      />
    </div>
  </div>
</template>

<script>
import { MAP_HEIGHT, MAP_WIDTH, SPRITE_SIZE } from '@/config/constants'

export default {
  name: 'Map',
  data: () => ({
    mapCss: {
      '--width': MAP_WIDTH + 'px',
      '--height': MAP_HEIGHT + 'px',
      '--sprite': SPRITE_SIZE + 'px'
    }
  }),
  computed: {
    tiles () {
      return this.$store.state.tiles
    }
  }
}
</script>

<style scoped>
.map {
  position: relative;
  top: 0px;
  left: 0px;
  background-color: rgb(42, 153, 42);
  width: var(--width);
  height: var(--height);
  border-radius: 5px;
}

.row {
  height: var(--sprite);
  margin: 0 !important;
}

.tile {
  display: inline-flex;
  height: var(--sprite);
  width: var(--sprite);
  /* height: 38px;
  width: 38px;
  border: 1px solid rgb(60, 255, 0); */
}

.instructor-1 {
  background-image: url('~@/assets/player.png');
  background-size: cover;
}

.healing_potion {
  background-image: url('~@/assets/potion.png');
  background-position: bottom right;
  background-size: 20px 20px;
  background-repeat: no-repeat;
}

.horse {
  background-image: url('~@/assets/horse.png');
  background-size: cover;
}

.cave {
  background-image: url('~@/assets/cave.png');
  background-size: cover;
}

.tent {
  background-image: url('~@/assets/tent.png');
  background-size: cover;
}

.sword {
  background-image: url('~@/assets/sword.png');
  background-position: bottom right;
  background-size: 23px 23px;
  background-repeat: no-repeat;
}

.pickaxe {
  background-image: url('~@/assets/pickaxe.png');
  background-position: bottom right;
  background-size: 23px 23px;
  background-repeat: no-repeat;
}

.hatchet {
  background-image: url('~@/assets/hatchet.png');
  background-position: bottom right;
  background-size: 23px 23px;
  background-repeat: no-repeat;
}

.tree {
  background-image: url('~@/assets/tree.png');
  background-size: cover;
}

.tree_two {
  background-image: url('~@/assets/tree2.png');
  background-size: cover;
}

.trunk {
  background-image: url('~@/assets/wooden_trunk.png');
  background-size: cover;
  background-size: 20px 10px;
  background-position: bottom center;
  background-repeat: no-repeat;
}

.portal {
  background-image: url('~@/assets/portal.png');
  background-size: cover;
}

.rock {
  background-image: url('~@/assets/stone.png');
  background-size: cover;
}

.rock_two {
  background-image: url('~@/assets/stones.png');
  background-size: cover;
}

.campfire {
  background-image: url('~@/assets/campfire.gif');
  background-size: cover;
}
</style>
