export const tiles9 = [
  [0, 9, 0, 0, 0, 10, 0, 0, 0, 0, 0, 11, 0, 0, 0, 0, 0, 0, 0, 10],
  [0, 0, 10, 0, 10, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 10, 0, 0, 10, 0, 0, 0, 10, 0, 0, 10, 0, 0],
  [10, 10, 0, 10, 0, 0, 10, 0, 0, 0, 0, 0, 10, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 10, 0, 0, 0, 0, 0, 10, 0, 0, 0, 0, 20],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10, 0, 0, 0, 0, 0, 0, 20, 21, 0],
  [10, 0, 0, 10, 11, 0, 10, 0, 0, 0, 0, 0, 10, 0, 0, 0, 20, 0, 0, 0],
  [0, 0, 10, 0, 0, 0, 0, 0, 10, 0, 0, 0, 0, 0, 0, 0, 20, 0, 101, 0],
  [0, 0, 0, 0, 0, 10, 0, 0, 0, 0, 0, 10, 0, 0, 21, 20, 0, 0, 0, 0],
  [10, 0, 0, 0, 0, 0, 0, 0, 10, 0, 0, 0, 0, 20, 0, 0, 0, 21, 0, 20]
]

export const monsters9 = [
  /* {
    type: 'hauntedEnt',
    position: [8, 18]
  } */
]
