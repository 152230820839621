import Mumus from '@/assets/mumus.png'

const mumus = {
  hp: 100,
  maxHp: 100,
  damage: 5,
  defence: 0,
  exp: 10,
  type: 'ent',
  sprite: Mumus
}

export default mumus
