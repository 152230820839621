var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"map",style:(_vm.mapCss)},_vm._l((_vm.tiles),function(row,index){return _c('div',{key:index,staticClass:"row"},_vm._l((row),function(tile,index){return _c('div',{key:index,staticClass:"tile",class:{
        'dust': tile === 1,
        'portal': tile === 5,
        'healing_potion': tile === 6,
        'sword': tile === 7,
        'pickaxe': tile === 8,
        'hatchet': tile === 9,
        'tree': tile === 10,
        'tree_two': tile === 11,
        'trunk': tile === 19,
        'rock': tile === 20,
        'rock_two': tile === 21,
        'campfire': tile === 100,
        'cave': tile === 101,
        'tent': tile === 48,
        'horse': tile === 49,
        'instructor-1': tile === 50
      }})}),0)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }