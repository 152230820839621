<template>
  <div class="world" :style="worldCss">
    <Map />
    <Player />
    <Monster
      v-for="(monster, index) in monsters"
      :key="generateRandomKey(index)"
      :monster="monster"
      :id="index"
    />
    <Npc-Dialoge />
    <Inventory />
    <Market />
    <Died />
  </div>
</template>

<script>
import { MAP_HEIGHT, MAP_WIDTH } from '@/config/constants'
import Map from '@/components/map/Map.vue'
import Player from '@/components/player/Player.vue'
import Monster from '@/components/opponent/Monster.vue'
import Inventory from '@/components/gameWindow/Inventory.vue'
import Died from '@/components/gameWindow/Died.vue'
import NpcDialoge from '@/components/gameWindow/Dialoge.vue'
import Market from '@/components/gameWindow/Market.vue'

export default {
  name: 'World',
  components: {
    Map,
    Player,
    Monster,
    Inventory,
    Died,
    NpcDialoge,
    Market
  },
  data: () => ({
    worldCss: {
      '--width': MAP_WIDTH + 'px',
      '--height': MAP_HEIGHT + 'px'
    }
  }),
  computed: {
    monsters () {
      return this.$store.state.monsters
    }
  },
  methods: {
    generateRandomKey (id) {
      // need to check might result same ID as result for different monsters
      return id + Math.floor(Math.random() * 100) + Math.floor(Math.random() * 100)
    }
  }
}
</script>

<style scoped>
.world {
  position: relative;
  width: var(--width);
  height: var(--height);
  margin: 0 auto;
}
</style>
