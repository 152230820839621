<template>
  <div
    class="monster"
    :style="monsterCss"
    v-if="monsterLiveData.hp > 0 && counter >= 0"
  >
    <!-- <span class="monster_stat">{{ monsterLiveData.hp }} / {{ monsterLiveData.maxHp }}</span> -->
    <v-progress-linear
      :value="monsterLiveData.hp / monsterLiveData.maxHp * 100"
      color="red darken-2"
      :buffer-value="monsterLiveData.maxHp"
      rounded
    >
    </v-progress-linear>
  </div>
</template>

<script>
import { SPRITE_SIZE } from '../../config/constants'
import monsters from '@/components/opponent/'

export default {
  name: 'Monster',
  props: {
    id: Number, // ID identifies the monster on the current map
    monster: Object // { type: String, position: number[] }
  },
  data: function () {
    return {
      monsterCss: {
        '--height': SPRITE_SIZE + 'px',
        '--width': SPRITE_SIZE + 'px',
        '--left': this.monster.position[1] * SPRITE_SIZE + 'px',
        '--top': this.monster.position[0] * SPRITE_SIZE + 'px',
        '--background-image': 'url("' + monsters[this.monster.type].sprite + '")'
      }
    }
  },
  watch: {
    counter: {
      deep: true,
      handler: function (newVal) {
        // trigger monster movement on screen
        this.monsterCss['--left'] = this.$store.state.actualOpponentList[this.id].position[1] * SPRITE_SIZE + 'px'
        this.monsterCss['--top'] = this.$store.state.actualOpponentList[this.id].position[0] * SPRITE_SIZE + 'px'
      }
    }
  },
  computed: {
    counter () {
      return this.$store.state.roundCounter
    },
    monsterLiveData () {
      return this.$store.state.actualOpponentList[this.id]
    }
  },
  created () {
    // Monsters should regiester themselves into the store
    this.$store.commit('monsterRegistration', {
      id: this.id,
      ...this.monster,
      ...monsters[this.monster.type],
      stilCount: true // lets scripts skip unnecessary calculations after monster dies
    })
  }
}
</script>

<style>
.monster {
  position: absolute;
  background-image: var(--background-image);
  background-size: cover;
  height: var(--height);
  width: var(--width);
  top: var(--top);
  left: var(--left);
}
.monster_stat {
  position: absolute;
  background-color: rosybrown;
  opacity: 0.7;
  border-radius: 7px;
  top: -16px;
  left: -2px;
  font-size: 10px;
  width: 43px;
}

.v-progress-linear__determinate {
  background-color:brown;
}

.v-progress-linear__buffer {
  border: 1px solid black;
}
</style>
