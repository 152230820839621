import hauntedEnt from './hauntedEnt'
import mumus from './mumus'
import wolf from './wolf'

const monsters = {
  hauntedEnt,
  mumus,
  wolf
}

export default monsters
